html {
  background: white;
  overflow-y: scroll;
  body {
    position: relative;
    z-index: 1;
    &::after {
      content: '';
      z-index: -1;
      position: fixed;
      top: 0;
      left: 0;
      background: #282c34;
      min-height: 100vh;
      min-width: 100vw;
    }
  }
  &,
  body::after,
  div,
  table {
    animation: appear 0.7s;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* width */
::-webkit-scrollbar {
  width: 20px;
  background: #282c34;
  animation: appear 0.7s;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #80808093;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1f2229;
  box-shadow: inset 2px 2px 5px #69697e;
  border-radius: 10px;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24pt;
  color: white;

  button {
    background: #333946;
    color: white;
    box-sizing: border-box;
    border-radius: 50px;
    margin: 5px 15px;
    padding: 15px;
    cursor: pointer;
    outline: none;
    border: none;
    transition: background 0.2s;

    &:hover {
      background: black;
    }

    &.disabled {
      background: #535864;
      &:hover {
        background: #535864;
      }
    }
  }

  label {
    cursor: pointer;
    transition: color 0.1s;
    &:hover {
      color: #69697e;
    }
  }

  .clickable {
    border-radius: 15px;
    padding: 0 5px;
    &:hover {
      cursor: pointer;
      color: #505050;
    }
  }

  .App-header {
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
    a:hover {
      color: inherit;
    }
    h1 {
      font-weight: 200;
      font-size: 84pt;
      line-height: 0;
    }
    p {
      line-height: 10px;
      margin: 0;
      pointer-events: none;
    }
    .quote {
      font-size: 8pt;
    }
    margin: 0 65px 35px 65px;
  }

  .highlight {
    font-weight: bold;
  }
}

h3 {
  font-weight: 500;
}

a {
  text-decoration: none;
  color: white;
  &:hover {
    cursor: pointer;
    color: grey;
  }
}

#app-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  opacity: 1;
  top: 0;
  left: 0;
  transition: opacity 0.3s;
  overflow: hidden;
  animation-name: modal-back;
  animation-duration: 0.3s;
  .content {
    transition: top 2s;
    position: relative;
    z-index: 100;
    top: 50%;
    animation-name: modal-content;
    animation-duration: 0.7s;
    background-color: #282c34;
    padding: 3%;
    transform: translate(0, -50%);
    div,
    div img {
      margin: auto;
    }
  }
  .back {
    position: absolute;
    z-index: 50;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #a7a7a76c;
    backdrop-filter: blur(2px);
    transition: backdrop-filter 0.8s;
  }
  &.hidden {
    opacity: 0;
  }
}

.loading {
  img {
    width: 45%;
    max-width: 100px;
  }
  padding: 50px;
}

#scrapping-status {
  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  position: absolute;
  left: 20px;
  @media only screen and (max-width: 800px) {
    position: relative;
    left: 0;
  }
  top: 13px;

  input[type='checkbox'] {
    display: none;
    &:checked {
      & + label {
        border: 1px solid green;
        color: green;
        &:hover {
          border: 1px solid darkgreen;
          color: darkgreen;
          &:before {
            background-color: darkgreen;
          }
        }
        &:before {
          transform: translateX(10px);
          background-color: green;
        }
      }
    }
  }

  label {
    font-size: 15px;
    font-weight: bold;
    display: flex;
    height: 35px;
    border: 1px solid;
    border-radius: 99em;
    position: relative;
    transition: transform 0.75s ease-in-out;
    transform-origin: 50% 50%;
    cursor: pointer;
    padding-left: 35px;
    padding-right: 10px;
    line-height: 35px;

    &:before {
      transition: transform 0.75s ease;
      content: '';
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      background: white;
      border-radius: 50%;
      top: 11px;
      left: 12px;
    }
    &:hover:before {
      background: #515153;
    }
  }

  &.deactivating {
    label {
      border: 1px solid orange;
      color: orange;
      &:before {
        background: orange;
      }
      &:hover {
        border: 1px solid darkorange;
        color: darkorange;
        &:before {
          background: darkorange;
        }
      }
    }
  }
}

@keyframes modal-content {
  from {
    top: 125%;
  }
  to {
    top: 50%;
  }
}

@keyframes modal-back {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
