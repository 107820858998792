#users-list {
  .list {
    text-align: left;
    margin: 25px 0 50px 0;
    a {
      &:nth-of-type(odd) {
        background: rgba(180, 180, 180, 0.1);
      }
      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: white;
      }
      width: 50vw;
      display: block;
      padding: 10px;
      position: relative;
      span + span {
        float: right;
      }
    }
  }
}
